<template>
  <AppModal
    render-close-button
    :show="$store.getters['showEnrollmentUnavailableModal']"
    @close="$store.dispatch('closeEnrollmentUnavailableModal');"
  >
    <div class="text-center">
      <p class="text-[2.75rem] leading-tight tracking-[0.005em] mb-11">
        Lincoln Personalization Studio<br />Enrollment will be available on {{ DATE }}.
      </p>
      <p class="text-[2rem] tracking-[0.005em] mb-10">
        Please return to enroll at that time.
      </p>
    </div>
  </AppModal>
</template>

<script setup>
import { format } from 'date-fns';
import AppModal from './AppModal.vue';

const DATE = format(new Date(process.env.VUE_APP_ENROLLMENT_AVAILABILITY_DATE), 'M/d');
</script>
