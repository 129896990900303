import { APP_IS_AVAILABLE_FOR_DOWNLOAD, events } from '@/constants';

export default function handleDownloadAppButtonClick({ store }) {
  store.dispatch('appInsights/trackEvent', { name: events.DOWNLOAD_APP_CLICK });

  if (!APP_IS_AVAILABLE_FOR_DOWNLOAD) {
    store.dispatch('openAppUnavailableModal');
    return;
  }

  if (!store.getters.userIsEnrolled) {
    store.dispatch('openNotEnrolledModal');
    return;
  }

  store.dispatch('openDownloadAppModal');
}
