import { events } from '@/constants';

export default [
  {
    name: 'Support',
    eventName: events.FAQ_SECTION_SUPPORT,
    content: `
      <h3>Who should I contact if I have more questions?</h3>
      <p>A dedicated program headquarters team is available for support. They are available Monday through Friday, from 9 am to 5 pm EST. You also can email at <a href="mailto:support@lps-info.com?subject=LPS Support Enquiry">support@lps-info.com</a> or call toll-free: 855-535-2807.</p>
      <h3>How do I get support?</h3>
      <p>A dedicated program headquarters team is available for support. They are available Monday through Friday, from 9 am to 5 pm EST. You also can email at <a href="mailto:support@lps-info.com?subject=LPS Support Enquiry">support@lps-info.com</a> or call toll-free: 855-535-2807.</p>
    `,
  },
  {
    name: 'Equipment',
    eventName: events.FAQ_SECTION_EQUIPMENT,
    content: `
      <h3>Do I need to purchase hardware / equipment?</h3>
      <p>Lincoln Retailers are responsible for their own equipment. You and your clients will experience an optimized App performance by using hardware and equipment noted in the “Equipment Requirements” section.</p>
      <h3>What hardware / equipment do I need?</h3>
      <div class="columns-2">
        <div>
          <p><b>Recommended Mobile device(s):</b></p>
          <p>Apple iPad Pro&reg;</p>
          <ul>
            <li>• 128 GB storage</li>
            <li>• 11” display</li>
            <li>• M1 processor</li>
            <li>• 8 GB RAM</li>
            <li>• Wi-Fi or cellular required</li>
          </ul>
        </div>
        <div>
          <p><b>For Windows-based PC device(s):</b></p>
          <ul>
            <li>• 12th-generation i5 processor or equivalent </li>
            <li>• RTX 3080 GPU or equivalent</li>
            <li>• 16 GB RAM</li>
            <li>• 60 GB storage minimum</li>
            <li>• Internet connection required</li>
          </ul>
        </div>
      </div>
      <h3>What do I need to project the app onto another monitor?</h3>
      <p>You will need an Apple TV&reg; to mirror and project the iPad&reg; screen. Also, you will need a smart TV with HDMI to connect to the Apple TV&reg; device to project the iPad&reg; onto a secondary monitor or computer screen.</p>
      <h3>How often will there be software updates?</h3>
      <p>The program software will be updated regularly and when new product, pricing, and software information is available.</p>
      <h3>How do I project the image from the tablet/iPad&reg; onto a larger monitor?</h3>
      <p class="mb-4">There are several options.</p>
      <ol class="list-decimal pl-8">
      <li class="mb-4">If you have a smart TV that has AirPlay&reg; support (AirPlay&reg; is Apple’s wireless sharing solution): Verify that your iPad&reg; and smart TV / monitor are connected to the same WiFi network. On the iPad&reg;, open the Control Center, tap on “Screen Mirroring”, and choose the smart TV you wish to mirror to. Your iPad&reg; and TV may ask you to confirm the connection by inputting a code. If so, follow the on-screen instructions. This is typically only done once for security reasons.</li>
      <li>If you have Apple TV&reg; (Apple’s set top box sold separately), verify that both your iPad&reg; and your Apple TV&reg; are connected to the same WiFi network. Once both are on the same network, an AirPlay&reg; icon will show up in your Control Center. Tap Control Center, select Apple TV&reg;, and enable mirroring.</li>
      </ol>
      <h3>How do I project the image from the Windows-based PC device onto a monitor?</h3>
      <p>To project or mirror your screen from the Windows-based PC to a monitor, connect the monitor to the PC with a cable. Then go to display settings on the PC and select ‘duplicate these displays.’ Alternatively, you can connect both the monitor and the Windows-based PC to a dock with a cable (one for each) and then go to your display settings on the PC and select ‘duplicate these displays’.</p>
      <p class="text-xs desktop-lg:text-sm font-semibold mt-10">Apple, the Apple logo, Apple TV&reg;, AirPlay&reg;, iPad&reg;, iPod&reg;, iTunes&reg;, iPhone&reg;, and Siri&reg; are trademarks of Apple Inc., registered in the U.S. and other countries.</p>
    `,
  },
  {
    name: 'Vehicles',
    eventName: events.FAQ_SECTION_VEHICLES,
    content: `
      <h3>Can I save the vehicles built?</h3>
      <p>Yes – You can save each configuration on the Summary page. If you prefer to save it longer than 24 hours, you will have the option to save it with an email address to look it up at a later date.</p>
      <h3>Can I email the vehicle configurations to a Client?</h3>
      <p>The App has a feature where you can email a Client or yourself directly from the App domain which is a no-reply account. We recommend sending the email to yourself and then sending your own personalized email to your client from the dealership email address.</p>
      <h3>Will the app allow me to place an order directly?</h3>
      <p>No – The App does not allow you to place an order directly. It will provide customized build configurations with all the build codes that would be necessary to then place an order into the current WEBDO system. However, vehicle orders will be handled through the regular ordering process or system.</p>
      <h3>Are Black Label vehicles included?</h3>
      <p>Only Black Label certified Retailers are able to view Black Label themes and configure Black Label vehicles.</p>
    `,
  },
  {
    name: 'Personalization Studio App',
    eventName: events.FAQ_SECTION_APP,
    content: `
      <h3>Can I subscribe to Lincoln Personalization Studio and use it on more than one device at the same time?</h3>
      <p>Yes – Only one subscription is necessary for each Lincoln Store. Once enrolled, the App can be used on multiple devices.</p>
      <h3>Is internet or Wi-Fi access required to use the app?</h3>
      <p>Your iPad&reg; will need to be connected to Wi-Fi (or cellular service) to help ensure the latest vehicle information is available.</p>
      <h3>What Client information will be saved on the app or devices?</h3>
      <p>The App has an optional feature to email your Client a summary of the Lincoln vehicle they configured. When this feature is used, your Client’s email address will be stored for a period of 24 hours. If this feature is not used, no Client information is saved or stored on the App or device(s).</p>
      <h3>Can I log in on all of my devices with one ID?</h3>
      <p>The App login requires a WSLX ID. This is the same ID used to log into FMCDealer. You may use one ID for all of your device(s) or you may use multiple IDs associated with your Lincoln Store to log in to multiple devices.</p>
      <h3>What do I need to project the app onto another monitor?</h3>
      <p>You will need an Apple TV&reg; to mirror and project the iPad&reg; screen. Also, you will need a smart TV with HDMI to connect to the Apple TV&reg; device to project the iPad&reg; onto a secondary monitor or computer screen.</p>
      <h3>How often will there be software updates?</h3>
      <p>The program software will be updated regularly and when new product, pricing, and software information is available.</p>
      <h3>Can I save the vehicles built?</h3>
      <p>Yes – You can save each configuration on the Summary page. If you prefer to save it longer than 24 hours, you will have the option to save it with an email address to look it up at a later date.</p>
      <h3>Can I email the vehicle configurations to a Client?</h3>
      <p>The App has a feature where you can email a Client or yourself directly from the App domain which is a no-reply account. We recommend sending the email to yourself and then sending your own personalized email to your client from the dealership email address.</p>
      <h3>Will the app allow me to place an order directly?</h3>
      <p>No – The App does not allow you to place an order directly. It will provide customized build configurations with all the build codes that would be necessary to then place an order into the current WEBDO system. However, vehicle orders will be handled through the regular ordering process or system.</p>
      <h3>Are Black Label vehicles included?</h3>
      <p>Only Black Label certified Retailers are able to view Black Label themes and configure Black Label vehicles.</p>
      <h3>How do I project the image from the tablet/iPad&reg; onto a larger monitor?</h3>
      <p class="mb-4">There are several options.</p>
      <ol class="list-decimal pl-8">
      <li class="mb-4">If you have a smart TV that has AirPlay&reg; support (AirPlay&reg; is Apple’s wireless sharing solution): Verify that your iPad&reg; and smart TV / monitor are connected to the same WiFi network. On the iPad&reg;, open the Control Center, tap on “Screen Mirroring”, and choose the smart TV you wish to mirror to. Your iPad&reg; and TV may ask you to confirm the connection by inputting a code. If so, follow the on-screen instructions. This is typically only done once for security reasons.</li>
      <li>If you have Apple TV&reg; (Apple’s set top box sold separately), verify that both your iPad&reg; and your Apple TV&reg; are connected to the same WiFi network. Once both are on the same network, an AirPlay&reg; icon will show up in your Control Center. Tap Control Center, select Apple TV&reg;, and enable mirroring.</li>
      </ol>
      <h3>How do I project the image from the Windows-based PC device onto a monitor?</h3>
      <p>To project or mirror your screen from the Windows-based PC to a monitor, connect the monitor to the PC with a cable. Then go to display settings on the PC and select ‘duplicate these displays.’ Alternatively, you can connect both the monitor and the Windows-based PC to a dock with a cable (one for each) and then go to your display settings on the PC and select ‘duplicate these displays’.</p>
      <p class="text-xs desktop-lg:text-sm font-semibold mt-10">Apple, the Apple logo, Apple TV&reg;, AirPlay&reg;, iPad&reg;, iPod&reg;, iTunes&reg;, iPhone&reg;, and Siri&reg; are trademarks of Apple Inc., registered in the U.S. and other countries.</p>
    `,
  },
  {
    name: 'Pricing',
    eventName: events.FAQ_SECTION_PRICING,
    content: `
      <h3>How much does the program cost?</h3>
      <p>The Lincoln Personalization Studio is a subscription-based program for a 12-month commitment. The monthly charge of $650 will be billed on the Retailer’s parts statement.</p>
      <h3>Is the LPS Program eligible for Lincoln Brand Exclusive Advertising (LBEAP)?</h3>
      <p>Yes, The Lincoln Personalization Studio Program is now eligible to be submitted as an eligible expense for the Lincoln Brand Exclusive Advertising Program (LBEAP), during 2023.</p>
      <h3>If I don’t like it, can I cancel?</h3>
      <p>The subscription is a 12-month commitment. Please contact Program Headquarters if you have concerns. We are available Monday-Friday, 9 am to 5 pm EST via email <a href="mailto:support@lps-info.com?subject=LPS Support Enquiry">support@lps-info.com</a> or phone at: 855-535-2807.</p>
      <h3>Will pricing show lease options?</h3>
      <p>No – The App currently shows an estimated MSRP as built.</p>
    `,
  },
  {
    name: 'General Questions',
    eventName: events.FAQ_SECTION_GENERAL,
    content: `
      <h3>Can I subscribe to Lincoln Personalization Studio and use it on more than one device at the same time?</h3>
      <p>Yes – Only one subscription is necessary for each Lincoln Store. Once enrolled, the App can be used on multiple devices.</p>
      <h3>Is internet or Wi-Fi access required to use the app?</h3>
      <p>Your iPad&reg; will need to be connected to Wi-Fi (or cellular service) to help ensure the latest vehicle information is available.</p>
      <h3>What Client information will be saved on the app or devices?</h3>
      <p>The App has an optional feature to email your Client a summary of the Lincoln vehicle they configured. When this feature is used, your Client’s email address will be stored for a period of 24 hours. If this feature is not used, no Client information is saved or stored on the App or device(s).</p>
      <h3>Is the LPS Program eligible for Lincoln Brand Exclusive Advertising (LBEAP)?</h3>
      <p>Yes, The Lincoln Personalization Studio Program is now eligible to be submitted as an eligible expense for the Lincoln Brand Exclusive Advertising Program (LBEAP), during 2023.</p>
      <h3>Can I log in on all of my devices with one ID?</h3>
      <p>The App login requires a WSLX ID. This is the same ID used to log into FMCDealer. You may use one ID for all of your device(s) or you may use multiple IDs associated with your Lincoln Store to log in to multiple devices.</p>
      <h3>What do I need to project the app onto another monitor?</h3>
      <p>You will need an Apple TV&reg; to mirror and project the iPad&reg; screen. Also, you will need a smart TV with HDMI to connect to the Apple TV&reg; device to project the iPad&reg; onto a secondary monitor or computer screen.</p>
      <h3>How often will there be software updates?</h3>
      <p>The program software will be updated regularly and when new product, pricing, and software information is available.</p>
      <h3>How do I project the image from the tablet/iPad&reg; onto a larger monitor?</h3>
      <p class="mb-4">There are several options.</p>
      <ol class="list-decimal pl-8">
      <li class="mb-4">If you have a smart TV that has AirPlay&reg; support (AirPlay&reg; is Apple’s wireless sharing solution): Verify that your iPad&reg; and smart TV / monitor are connected to the same WiFi network. On the iPad&reg;, open the Control Center, tap on “Screen Mirroring”, and choose the smart TV you wish to mirror to. Your iPad&reg; and TV may ask you to confirm the connection by inputting a code. If so, follow the on-screen instructions. This is typically only done once for security reasons.</li>
      <li>If you have Apple TV&reg; (Apple’s set top box sold separately), verify that both your iPad&reg; and your Apple TV&reg; are connected to the same WiFi network. Once both are on the same network, an AirPlay&reg; icon will show up in your Control Center. Tap Control Center, select Apple TV&reg;, and enable mirroring.</li>
      </ol>
      <h3>How do I project the image from the Windows-based PC device onto a monitor?</h3>
      <p>To project or mirror your screen from the Windows-based PC to a monitor, connect the monitor to the PC with a cable. Then go to display settings on the PC and select ‘duplicate these displays.’ Alternatively, you can connect both the monitor and the Windows-based PC to a dock with a cable (one for each) and then go to your display settings on the PC and select ‘duplicate these displays’.</p>
      <h3>If I don’t like it, can I cancel?</h3>
      <p>The subscription is a 12-month commitment. Please contact Program Headquarters if you have concerns. We are available Monday-Friday, 9 am to 5 pm EST via email <a href="mailto:support@lps-info.com?subject=LPS Support Enquiry">support@lps-info.com</a> or phone at: 855-535-2807.</p>
      <p class="text-xs desktop-lg:text-sm font-semibold mt-10">Apple, the Apple logo, Apple TV&reg;, AirPlay&reg;, iPad&reg;, iPod&reg;, iTunes&reg;, iPhone&reg;, and Siri&reg; are trademarks of Apple Inc., registered in the U.S. and other countries.</p>
    `,
  },
];
