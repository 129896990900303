import { events } from '@/constants';

export default [
  {
    id: 1,
    title: 'Program Overview',
    src: 'https://player.vimeo.com/video/790485342?h=e5b7190aea',
    thumbnail: 'program-overview-thumbnail.webp',
    slug: 'program-overview',
    events: {
      played: events.VIDEO_PROGRAM_OVERVIEW_PLAYED,
    },
  },
  {
    id: 2,
    title: 'Retailer Testimonial',
    src: 'https://player.vimeo.com/video/803098921?h=ccfff886e3',
    thumbnail: 'retailer-testimonial-thumbnail.webp',
    slug: 'retailer-testimonial',
    events: {
      played: events.VIDEO_RETAILER_TESTIMONIAL_PLAYED,
    },
  },
  {
    id: 3,
    title: 'Tutorial',
    src: 'https://player.vimeo.com/video/805961904?h=e37af08f04',
    thumbnail: 'tutorial-thumbnail.webp',
    slug: 'tutorial',
    events: {
      played: events.VIDEO_TUTORIAL_PLAYED,
    },
  },
];
