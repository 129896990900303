<template>
  <footer
    class="
      bg-tertiary
      text-white
      py-10
      desktop-lg:pt-20 desktop-lg:pb-28
      font-light
    "
    :id="sectionId.SUPPORT"
    :data-track-event="events.NAVIGATION_SECTION_SUPPORT"
  >
    <div class="app-container">
      <div
        class="flex max-desktop:flex-col-reverse justify-between gap-8 mb-16"
      >
        <div class="max-w-[46.5rem]">
          <h3
            class="
              font-semibold
              tracking-[0.065em]
              mt-4
              mb-2
              text-xl
              desktop-lg:text-2xl
            "
          >
            Lincoln Personalization Studio Program Support Information
          </h3>
          <div class="desktop-lg:text-xl">
            <p>
              Lincoln Personalized Studio program includes a dedicated team to
              help provide personalized support.
            </p>
            <div class="my-5">
              <p>
                <span class="font-semibold">Email: </span>
                <a
                  href="mailto:support@lps-info.com?subject=LPS Support Enquiry"
                  class="
                    underline underline-offset-[6px]
                    decoration-1 decoration-secondary
                  "
                >
                  support@lps-info.com
                </a>
              </p>
              <p>
                <span class="font-semibold">Call toll-free: </span>
                <a href="tel:8555352807">855-535-2807</a>
              </p>
            </div>
          </div>
          <p>
            Consultants are available Monday through Friday, from 9 am to 5 pm
            EST.
          </p>
        </div>
        <img
          src="@/assets/images/lps-logo-footer.svg"
          alt="Lincoln personalization studio logo"
          class="max-desktop:self-center self-start h-[3rem] tablet:h-[5rem]"
        />
      </div>
      <p class="mb-2.5">Important Notes:</p>
      <p class="text-xs desktop-lg:text-sm mb-2.5">
        Website is for demonstration purposes only. Information (including MSRP)
        may not be current and is subject to change.
      </p>
      <p class="text-xs desktop-lg:text-sm mb-2.5">
        Apple, the Apple logo, Apple TV&reg;, AirPlay&reg;, iPad&reg;,
        iPod&reg;, iTunes&reg;, iPhone&reg;, and Siri&reg; are trademarks of
        Apple Inc., registered in the U.S. and other countries.
      </p>
      <p class="text-xs desktop-lg:text-sm">
        Copyright © {{ YEAR }} Ford Motor Company. All rights reserved. All
        text, images, graphics, animation, videos, music, sounds, and other
        materials on this website (“site”) are subject to the copyrights and
        other intellectual property rights of Ford Motor Company, its affiliated
        companies, and its licensors. Ford Motor Company owns the copyrights in
        the selection, coordination, and arrangement of the materials on this
        site. These materials may not be copied for commercial use or
        distribution, nor may these materials be modified or reposted to other
        sites.
      </p>
    </div>
  </footer>
</template>

<script setup>
import { events, sectionId } from '@/constants';

const YEAR = new Date().getFullYear();
</script>
