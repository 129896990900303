<template>
  <AppModal
    render-close-button
    :show="$store.getters['showNotEnrolledModal']"
    @close="$store.dispatch('closeNotEnrolledModal')"
  >
    <div class="text-center">
      <p class="text-[2rem] leading-tight tracking-[0.005em] mb-11">
        This user ID is not a valid subscriber. <br />
        Please check your ID or enroll by clicking
        <BaseButton
          class="
            font-bold
            underline underline-offset-[6px]
            decoration-1 decoration-secondary
          "
          :to="ENROLL_LINK"
          >Enroll Now</BaseButton
        >.
      </p>
      <p class="text-[1.5rem] tracking-[0.005em] mb-10">
        For additional assistance, please contact PHQ at<br />
        Email:
        <a
          class="
            underline underline-offset-[6px]
            decoration-1 decoration-secondary
          "
          href="mailto:support@lps-info.com?subject=LPS Support Enquiry"
          >support@lps-info.com</a
        >
        <br />
        Call toll-free: 855-535-2807
      </p>
    </div>
  </AppModal>
</template>

<script setup>
import BaseButton from './BaseButton.vue';
import AppModal from './AppModal.vue';

const ENROLL_LINK = process.env.VUE_APP_ENROLL_LINK;
</script>
