import { APP_IS_AVAILABLE_FOR_DOWNLOAD, events } from '@/constants';

export default function handleDownloadUserGuideButtonClick({ store }) {
  store.dispatch('appInsights/trackEvent', { name: events.DOWNLOAD_USER_GUIDE_CLICK });

  if (!APP_IS_AVAILABLE_FOR_DOWNLOAD) {
    store.dispatch('openUserGuideModal');
    return;
  }

  if (!store.getters.userIsEnrolled) {
    store.dispatch('openNotEnrolledModal');
    return;
  }

  store.dispatch('openDownloadUserGuideModal');
}
