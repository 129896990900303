import axios from 'axios';

import Authenticate from './authenticate';
import Download from './download';

const success = (response) => response;
const failure = (error) => Promise.reject(error);

class ApiManager {
  constructor() {
    this.requiresAuth = false;
    // Core api for project
    this.coreObject = axios.create({
      baseURL: process.env.VUE_APP_BASE_API_APP_URL,
    });

    this.authenticate = new Authenticate(this.coreObject);
    this.download = new Download(this.coreObject);

    this.coreObject.interceptors.response.use(success, failure);
  }

  /**
   * Add any APIs that require authentication to the array within method.
   */
  setAuthHeader(authToken) {
    [
      this.download,
      // add API needing authentication here
    ].forEach((api) => api.setAuthHeader(authToken));
  }
}

export default new ApiManager();
