import { baseCRUDAPI } from './base';

export default class Authenticate extends baseCRUDAPI {
  constructor(axiosObject) {
    super(axiosObject, 'Auth');
  }

  async loginURL() {
    return this.axiosObject.get(
      `${this.apiBaseName}/Ford/LoginURL`,
    );
  }

  async token(accessTokenObject) {
    return this.axiosObject.post(
      `${this.apiBaseName}/Ford/Token`,
      accessTokenObject,
    );
  }
}
