<template>
  <label
    v-bind="$attrs.class"
    class="checkbox"
    :for="id"
  >
    <input
      v-bind="$attrs"
      class="checkbox__input"
      type="checkbox"
      :id="id"
      :checked="modelValue"
      @change="handleChange"
    />
    <div class="checkbox__control">
      <svg class="checkbox__control-icon" width="24" height="24" viewBox="0 0 24 24">
        <path d="m10 15.586-3.293-3.293-1.414 1.414L10 18.414l9.707-9.707-1.414-1.414z" />
      </svg>
    </div>
    <div class="checkbox__content">
      <span class="font-semibold">
        <slot />
      </span>
    </div>
  </label>
</template>

<script>
export default {
  inheritAttrs: false,
};
</script>

<script setup>
const emit = defineEmits(['update:modelValue']);
defineProps({
  id: {
    type: String,
    required: true,
  },
  modelValue: {
    type: Boolean,
    default: false,
  },
});

function handleChange(event) {
  emit('update:modelValue', event.target.checked);
}
</script>

<style lang="scss" scoped>
.checkbox {
  --size: 1.25rem;
  --color: #6e6e6e;
  display: grid;
  grid-template-columns: var(--size) 1fr;
  gap: 1rem;
  width: fit-content;
  position: relative;
  &__control {
    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--size);
    height: var(--size);
    color: var(--color);
    border: 1px solid var(--color);
    position: relative;
    font-size: 0.75rem;
    transform: translateY(0.375rem);
    cursor: pointer;
    &::after {
      content: '';
      display: none;
      position: absolute;
      inset: 0;
      outline: 1px solid #293338;
      outline-offset: 8px;
    }
  }
  &__control-icon {
    display: none;
    fill: #fff;
  }
  &__input {
    width: 0;
    height: 0;
    opacity: 0;
    position: absolute;
    &:checked + .checkbox__control {
      --color: #F26147;
      background-color: #F26147;
      .checkbox__control-icon {
        display: block;
      }
    }
    &:focus-visible + .checkbox__control::after {
      display: block;
    }
  }
}
</style>
