<template>
  <TheHeader />
  <main id="main" class="flex-1 container bg-white">
    <slot />
  </main>
  <TheFooter />
  <ModalDownloadApp />
  <ModalNotEnrolled />
  <ModalAppUnavailable />
  <ModalEnrollmentUnavailable />
  <ModalUserGuideUnavailable />
  <ModalDownloadUserGuide />
</template>

<script setup>
import { watch } from 'vue';
import { useStore } from 'vuex';
import TheHeader from '@/components/TheHeader.vue';
import TheFooter from '@/components/TheFooter.vue';
import ModalDownloadApp from '@/components/ModalDownloadApp.vue';
import ModalNotEnrolled from '@/components/ModalNotEnrolled.vue';
import ModalAppUnavailable from '@/components/ModalAppUnavailable.vue';
import ModalEnrollmentUnavailable from '@/components/ModalEnrollmentUnavailable.vue';
import ModalUserGuideUnavailable from '@/components/ModalUserGuideUnavailable.vue';
import ModalDownloadUserGuide from '@/components/ModalDownloadUserGuide.vue';

const store = useStore();

window.addEventListener('scroll', (event) => {
  store.dispatch('updateActiveSectionId', event);
});

// Track section scroll
function trackSectionScroll() {
  const sectionTrackEvent = document
    .querySelector(`#${store.getters.activeSectionId}`)
    ?.getAttribute('data-track-event');

  if (sectionTrackEvent) {
    store.dispatch('appInsights/trackEvent', { name: sectionTrackEvent });
  }
}

watch(() => store.getters.activeSectionId, trackSectionScroll);
</script>
