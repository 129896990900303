<template>
  <section
    class="app-container py-[8rem]"
    :id="sectionId.QUESTIONS"
    :data-track-event="events.NAVIGATION_SECTION_QUESTIONS"
  >
    <AppTitle class="text-center mb-[4rem]">
      Questions?
    </AppTitle>
    <div class="
      grid
      tablet-sm:grid-cols-[auto_auto]
      w-full
      max-w-[66rem]
      mx-auto
      bg-white
      shadow-[0_20px_40px_#D8D8D8]
      border
      border-gray2
    ">
      <ul class="w-full tablet-sm:max-w-[20rem]">
        <li
          v-for="section, index in sections"
          class="[:not(:last-child)&>button]:border-b [&>button]:border-b-white"
          :key="index"
        >
          <button
            v-text="section.name"
            type="button"
            class="
              question-button
              w-full
              h-[6rem]
              px-[3rem]
              py-4
              bg-tertiary
              text-white
              text-left
              text-[1.125rem]
              relative
              overflow-hidden
              after:content-['']
              after:w-[4px]
              after:bg-secondary
              after:absolute
              after:top-0
              after:bottom-0
              after:right-[-4px]
              after:transition-[right]
            "
            :class="{ 'after:right-0': activeSectionIndex === index }"
            @click="activeSectionIndex = index"
          />
        </li>
      </ul>
      <div class="p-[0.25rem]">
        <div
          v-html="sections[activeSectionIndex].content"
          :key="activeSectionIndex"
          class="
          scrollbar
          h-[calc(var(--sections)_*_6rem_-_0.5rem)]
          py-[3rem]
          px-[2rem]
          tablet:py-[4rem]
          tablet:px-[3rem]
          text-[#5D5D5D]
          text-[1rem]
          overflow-auto
          [&>h3]:text-[1.25rem]
          [&>h3:not(:first-of-type)]:mt-8
          [&>h3]:mb-2
          [&>h3]:font-bold"
          :style="`--sections: ${sections.length}`"
        />
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref, watch } from 'vue';
import { useStore } from 'vuex';
import { events, sectionId } from '@/constants';
import sections from '@/data/FAQSections';
import AppTitle from './AppTitle.vue';

const store = useStore();

const activeSectionIndex = ref(0);

// Track FAQ section view
function trackFAQSectionView() {
  store.dispatch('appInsights/trackEvent', {
    name: sections[activeSectionIndex.value]?.eventName,
  });
}

watch(() => activeSectionIndex.value, trackFAQSectionView);

let unwatchSectionView;

function handleSectionView(id) {
  if (id !== sectionId.QUESTIONS) return;

  trackFAQSectionView();

  if (unwatchSectionView) {
    unwatchSectionView();
  }
}

unwatchSectionView = watch(() => store.getters.activeSectionId, handleSectionView);
</script>
