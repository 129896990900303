<template>
  <BaseButton
    :class="buttonClasses"
    :disabled="disabled"
    :to="to"
    @click="handleClick"
  >
    <span class="button__text tracking-[0.075em] uppercase">
      <slot />
    </span>
  </BaseButton>
</template>

<script setup>
import { computed } from 'vue';
import BaseButton from './BaseButton.vue';

const emit = defineEmits(['click']);
const props = defineProps({
  to: {
    type: [String, Object],
    default: '',
  },
  secondary: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

/** CSS MODIFIERS */
const buttonClasses = computed(() => ({
  button: true,
  'button--primary': !props.secondary,
  'button--secondary': props.secondary,
  'button--disabled': props.disabled,
}));

function handleClick(event) {
  /**
   * Prevents <a> and <router-link> elements from being activated
   * with Tab + Enter keys when button is disabled
   */
  if (props.disabled) {
    event.preventDefault();
    return;
  }

  emit('click');
}
</script>

<style lang="scss" scoped>

.button {
  @apply
    flex
    justify-center
    items-center
    min-w-[17rem]
    w-fit
    min-h-[4rem]
    px-[1.5rem]
    text-[1.25rem]
    relative
    bg-transparent
    whitespace-nowrap
    no-underline
    outline-0
    transition-colors
    duration-150
    ease-in-out
    text-white
    drop-shadow-md;
}

.button::before {
  @apply
    content-['']
    h-[0.125rem]
    bg-secondary
    absolute
    left-0
    right-0
    bottom-0
    transition-[height]
    duration-150
    ease-in-out;
}
.button::after {
  @apply
    content-['']
    absolute
    inset-0;
}

.button:not(:disabled):focus-visible::before,
.button:not(:disabled):hover::before {
  @apply h-[0.25rem];
}

.button:focus-visible::after {
  @apply outline-1 outline outline-offset-4;
}

.button--disabled {
  cursor: default;
  &::before {
    display: none;
  }
}

/** PRIMARY */
.button--primary {
  @apply bg-dark2 active:bg-dark;
}

.button--primary.button--disabled {
  @apply bg-gray4;
}

.button--primary:focus-visible::after {
  @apply outline-dark2;
}

/** SECONDARY */
.button--secondary {
  @apply text-dark2 bg-white;
}
.button--secondary:focus-visible::after {
  @apply outline-white;
}
.button--secondary:disabled {
  @apply text-white bg-gray4;
}
</style>
