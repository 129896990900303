<template>
  <header id="header" class="sticky top-0 z-50 flex flex-col">
    <div class="min-h-[6rem] bg-white">
      <div class="
        app-container
        my-[1.25rem]
        h-full
        flex
        flex-col
        items-center
        gap-[1rem]
        tablet:flex-row
        tablet:justify-between
      ">
        <button
          type="button"
          aria-label="Scroll to top"
          @click="scrollToTop"
        >
          <img
            class="w-[10.625rem]"
            src="@/assets/images/lps-logo.svg"
            alt="Lincoln personalization studio logo"
          />
        </button>
        <div class="
          flex
          items-center
          font-medium
          text-[1rem]
          desktop-xl:text-[1.375rem]
          max-tablet-sm:flex-col
          max-tablet-sm:gap-2
        ">
          <ButtonEnrollNow>
            <template #available="{ link, onClickFn }">
              <TheHeaderLink :to="link" @click="onClickFn">
                Enroll Now
              </TheHeaderLink>
            </template>
            <template #not-available="{ onClickFn }">
              <TheHeaderLink @click="onClickFn">
                Enroll Now
              </TheHeaderLink>
            </template>
          </ButtonEnrollNow>
          <div class="
            bg-[#B4B4B4]
            w-[1px]
            h-[54px]
            mx-6
            desktop-xl:mx-12
            max-tablet-sm:hidden"
          />
          <TheHeaderLink @click="handleDownloadAppButtonClick({ store: $store })">
            Download App
          </TheHeaderLink>
          <div class="
            bg-[#B4B4B4]
            w-[1px]
            h-[54px]
            mx-6
            desktop-xl:mx-12
            max-tablet-sm:hidden"
          />
          <TheHeaderLink @click="handleDownloadUserGuideButtonClick({ store: $store })">
            Download User Guide
          </TheHeaderLink>
        </div>
      </div>
    </div>
    <div class="
      bg-disabled2
      border-t border-[#989898]
      shadow-[0_6px_11px_#00000052]
    ">
      <nav
        class="app-container h-14 flex items-center justify-end overflow-hidden"
        aria-label="primary navigation"
      >
        <ul class="flex justify-between w-full max-w-[53.8125rem] text-base desktop-xl:text-lg">
          <NavLink
            :to="`#${sectionId.VIDEO}`"
            :is-active="activeSectionId === sectionId.VIDEO"
          >
            Videos
          </NavLink>
          <NavLink
            :to="`#${sectionId.PROGRAM_INFO}`"
            :is-active="activeSectionId === sectionId.PROGRAM_INFO"
          >
            Program Info
          </NavLink>
          <NavLink
            :to="`#${sectionId.EQUIPMENT_REQUIREMENTS}`"
            :is-active="activeSectionId === sectionId.EQUIPMENT_REQUIREMENTS"
          >
            Equipment Requirements
          </NavLink>
          <NavLink
            :to="`#${sectionId.QUESTIONS}`"
            :is-active="activeSectionId === sectionId.QUESTIONS"
          >
            Questions?
          </NavLink>
          <NavLink
            :to="`#${sectionId.SUPPORT}`"
            :is-active="activeSectionId === sectionId.SUPPORT"
          >
            Support
          </NavLink>
        </ul>
      </nav>
    </div>
  </header>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import handleDownloadAppButtonClick from '@/utils/handleDownloadAppButtonClick';
import handleDownloadUserGuideButtonClick from '@/utils/handleDownloadUserGuideButtonClick';
import { sectionId } from '@/constants';
import NavLink from './NavLink.vue';
import ButtonEnrollNow from './ButtonEnrollNow.vue';
import TheHeaderLink from './TheHeaderLink.vue';

const store = useStore();

const activeSectionId = computed(() => store.getters.activeSectionId);

// Scroll to top
function scrollToTop() {
  window.scrollTo({ top: 0, behavior: 'smooth' });
}
</script>
