<template>
  <AppModal
    size="lg"
    :show="$store.getters['showDownloadAppModal']"
    :render-close-button="false"
    @close="$store.dispatch('closeDownloadAppModal')"
  >
    <p class="mb-[0.25rem]">
      As noted in the enrollment contract, this application may only be used by authorized users
      authenticated with a WSLX ID from Ford Motor Company and for Lincoln Business purposes only.
      Other usage is not permitted.
    </p>
    <AppCheckbox
      v-model="userAcceptedUsageTerms"
      id="download-app--usage-checkbox"
    >
      I Agree
    </AppCheckbox>
    <p class="mt-[1.5rem] mb-[0.25rem]">
      The device this App will be downloaded on meets the minimum requirements to
      enable this experience.
    </p>
    <AppCheckbox
      v-model="userAcceptedDownloadTerms"
      id="download-app--download-checkbox"
    >
      I Agree
    </AppCheckbox>
    <div class="flex flex-col gap-[3rem] mt-[3rem] desktop:flex-row desktop:gap-[2rem]">
      <div class="flex-1 desktop:border-r desktop:border-gray2 desktop:pr-[2rem]">
        <b class="pl-[1.5rem]">Apple iPad Pro®</b>
        <ul class="app-list text-[1rem] mt-[1rem] mb-[1.75rem]">
          <li>128 GB storage</li>
          <li>11” display</li>
          <li>M1 processor</li>
          <li>8 GB RAM</li>
          <li>Wi-Fi or cellular required</li>
        </ul>
        <AppButton
          class="!w-full"
          :disabled="!allowDownload"
          :title="buttonTitle"
          :to="$store.getters['iosDownloadLink']"
          @click="$store.dispatch('appInsights/trackEvent', { name: events.DOWNLOAD_IPAD_APP });"
        >
          Download IPad&reg; App
        </AppButton>
      </div>
      <div class="flex-1">
        <b class="pl-[1.5rem]">Windows-Based PC Device(s)</b>
        <ul class="app-list text-[1rem] mt-[1rem] mb-[1.75rem]">
          <li>12th-generation i5 processor or equivalent</li>
          <li>RTX 3080 GPU or equivalent</li>
          <li>16 GB RAM</li>
          <li>60 GB storage minimum</li>
          <li>Internet connection required</li>
        </ul>
        <AppButton
          class="!w-full"
          :disabled="!allowDownload"
          :title="buttonTitle"
          :to="$store.getters['pcDownloadLink']"
          @click="$store.dispatch('appInsights/trackEvent', { name: events.DOWNLOAD_WINDOWS_APP });"
        >
          Download Windows-Based PC App
        </AppButton>
      </div>
    </div>
    <p class="text-xs mt-[3rem] -mb-[1.5rem] text-center">
      LPS App. Compatible with select iPad®’s and Windows-based PCs. Is available via download.
      Message and data rates may apply.
    </p>
  </AppModal>
</template>

<script setup>
import { ref, computed } from 'vue';
import { events } from '@/constants';
import AppButton from './AppButton.vue';
import AppCheckbox from './AppCheckbox.vue';
import AppModal from './AppModal.vue';

const userAcceptedUsageTerms = ref(false);
const userAcceptedDownloadTerms = ref(false);

const allowDownload = computed(() => (
  userAcceptedUsageTerms.value && userAcceptedDownloadTerms.value
));

const buttonTitle = computed(() => {
  if (!allowDownload.value) {
    return 'Please agree to the terms of use.';
  }

  return null;
});
</script>
