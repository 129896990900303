<template>
  <AppModal
    :show="$store.getters['showDownloadUserGuideModal']"
    :render-close-button="false"
    @close="$store.dispatch('closeDownloadUserGuideModal')"
  >
    <div class="text-center mt-[2rem]">
      <p class="text-[2.75rem] leading-tight tracking-[0.005em] mb-11">
        Please select one of the following:
      </p>
      <AppButton
        class="!w-full max-w-[37.5rem] mx-auto mb-[2rem]"
        download="Lincoln_Personalization_Studio_User_Guide_IPAD.pdf"
        :to="USER_GUIDE_PATH_IPAD"
        @click="
          $store.dispatch('appInsights/trackEvent', {
            name: events.DOWNLOAD_IPAD_USER_GUIDE,
          })
        "
      >
        Download IPad&reg; User Guide
      </AppButton>
      <AppButton
        class="!w-full max-w-[37.5rem] mx-auto"
        download="Lincoln_Personalization_Studio_User_Guide_PC.pdf"
        :to="USER_GUIDE_PATH_PC"
        @click="
          $store.dispatch('appInsights/trackEvent', {
            name: events.DOWNLOAD_WINDOWS_USER_GUIDE,
          })
        "
      >
        Download Windows-Based PC User Guide
      </AppButton>
    </div>
  </AppModal>
</template>

<script setup>
import { events } from '@/constants';
import AppButton from './AppButton.vue';
import AppModal from './AppModal.vue';

// User Guide paths
const USER_GUIDE_PATH_IPAD = `${process.env.BASE_URL}documents/Lincoln_Personalization_Studio_User_Guide_IPAD.pdf`;
const USER_GUIDE_PATH_PC = `${process.env.BASE_URL}documents/Lincoln_Personalization_Studio_User_Guide_PC.pdf`;
</script>
