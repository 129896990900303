import { baseCRUDAPI } from './base';

export default class Download extends baseCRUDAPI {
  constructor(axiosObject) {
    super(axiosObject, 'Download');
  }

  async downloadIOS() {
    return this.axiosObject.get(
      `${this.apiBaseName}/ios`,
    );
  }

  async downloadPC() {
    return this.axiosObject.get(
      `${this.apiBaseName}/pc`,
    );
  }
}
