import { createStore } from 'vuex';
import apiManager from '@/api';
import { sectionId } from '@/constants';
import appInsights from './modules/appInsights';

export default createStore({
  modules: {
    appInsights,
  },
  state() {
    return {
      authToken: null,
      userIsEnrolled: true,
      iosDownloadLink: null,
      pcDownloadLink: null,
      showDownloadAppModal: false,
      showDownloadUserGuideModal: false,
      showNotEnrolledModal: false,
      showAppUnavailableModal: false,
      showEnrollmentUnavailableModal: false,
      showUserGuideModal: false,
      activeSectionId: sectionId.HOME,
    };
  },
  getters: {
    userIsAuthenticated: ({ authToken }) => !!authToken,
    userIsEnrolled: ({ userIsEnrolled }) => userIsEnrolled,
    iosDownloadLink: ({ iosDownloadLink }) => iosDownloadLink,
    pcDownloadLink: ({ pcDownloadLink }) => pcDownloadLink,
    showDownloadAppModal: ({ showDownloadAppModal }) => showDownloadAppModal,
    showDownloadUserGuideModal: ({ showDownloadUserGuideModal }) => showDownloadUserGuideModal,
    showNotEnrolledModal: ({ showNotEnrolledModal }) => showNotEnrolledModal,
    showAppUnavailableModal: ({ showAppUnavailableModal }) => showAppUnavailableModal,
    showEnrollmentUnavailableModal: ({ showEnrollmentUnavailableModal: show }) => show,
    showUserGuideModal: ({ showUserGuideModal }) => showUserGuideModal,
    activeSectionId: ({ activeSectionId }) => activeSectionId,
  },
  mutations: {
    setShowDownloadAppModal(state, show) {
      state.showDownloadAppModal = show;
    },
    setShowDownloadUserGuideModal(state, show) {
      state.showDownloadUserGuideModal = show;
    },
    setUserIsEnrolled(state, isEnrolled) {
      state.userIsEnrolled = isEnrolled;
    },
    setShowNotEnrolledModal(state, show) {
      state.showNotEnrolledModal = show;
    },
    setShowAppUnavailableModal(state, show) {
      state.showAppUnavailableModal = show;
    },
    setShowEnrollmentUnavailableModal(state, show) {
      state.showEnrollmentUnavailableModal = show;
    },
    setShowUserGuideModal(state, show) {
      state.showUserGuideModal = show;
    },
    setAuthToken(state, token) {
      state.authToken = token;
    },
    setIOSDownloadLink(state, link) {
      state.iosDownloadLink = link;
    },
    setPCDownloadLink(state, link) {
      state.pcDownloadLink = link;
    },
    setActiveSectionId(state, id) {
      state.activeSectionId = id;
    },
  },
  actions: {
    setAuthToken({ commit }, token) {
      apiManager.setAuthHeader(token);

      commit('setAuthToken', token);
    },
    openDownloadAppModal({ commit }) {
      commit('setShowDownloadAppModal', true);
    },
    closeDownloadAppModal({ commit }) {
      commit('setShowDownloadAppModal', false);
    },
    openDownloadUserGuideModal({ commit }) {
      commit('setShowDownloadUserGuideModal', true);
    },
    closeDownloadUserGuideModal({ commit }) {
      commit('setShowDownloadUserGuideModal', false);
    },
    openNotEnrolledModal({ commit }) {
      commit('setShowNotEnrolledModal', true);
    },
    closeNotEnrolledModal({ commit }) {
      commit('setShowNotEnrolledModal', false);
    },
    openAppUnavailableModal({ commit }) {
      commit('setShowAppUnavailableModal', true);
    },
    closeAppUnavailableModal({ commit }) {
      commit('setShowAppUnavailableModal', false);
    },
    openEnrollmentUnavailableModal({ commit }) {
      commit('setShowEnrollmentUnavailableModal', true);
    },
    closeEnrollmentUnavailableModal({ commit }) {
      commit('setShowEnrollmentUnavailableModal', false);
    },
    openUserGuideModal({ commit }) {
      commit('setShowUserGuideModal', true);
    },
    closeUserGuideModal({ commit }) {
      commit('setShowUserGuideModal', false);
    },
    updateActiveSectionId({ commit }, event) {
      const THRESHOLD_PIXELS = 1;
      const sections = document.querySelectorAll('section[id], footer[id]');
      const headerHeight = document.querySelector('header#header').offsetHeight;
      const {
        scrollTop,
        clientHeight: targetClientHeight,
        scrollHeight: targetScrollHeight,
      } = event.target.documentElement;

      // If scroll arrived at the bottom of the page, set last section as active
      if (scrollTop + targetClientHeight >= targetScrollHeight - THRESHOLD_PIXELS) {
        commit('setActiveSectionId', sections[sections.length - 1].getAttribute('id'));
        return;
      }

      sections.forEach((section) => {
        const sectionHeight = section.offsetHeight;
        const distanceFromTop = section.offsetTop - headerHeight;

        if (scrollTop > distanceFromTop && scrollTop <= distanceFromTop + sectionHeight) {
          commit('setActiveSectionId', section.getAttribute('id'));
        }
      });
    },
  },
});
