<template>
  <slot
    v-if="ENROLLMENT_IS_AVAILABLE"
    name="available"
    :link="ENROLL_LINK"
    :on-click-fn="trackEvent"
  >
    <AppButton
      v-bind="$attrs"
      secondary
      :to="ENROLL_LINK"
      @click="trackEvent"
    >
      Enroll now
    </AppButton>
  </slot>
  <slot
    v-else
    name="not-available"
    :on-click-fn="handleClick"
  >
    <AppButton
      v-bind="$attrs"
      secondary
      @click="handleClick"
    >
      Enroll now
    </AppButton>
  </slot>
</template>

<script setup>
import { useStore } from 'vuex';
import { events, ENROLLMENT_IS_AVAILABLE } from '@/constants';
import AppButton from './AppButton.vue';

const store = useStore();
const ENROLL_LINK = process.env.VUE_APP_ENROLL_LINK;

function trackEvent() {
  store.dispatch('appInsights/trackEvent', { name: events.ENROLL_NOW_CLICK });
}

function handleClick() {
  store.dispatch('openEnrollmentUnavailableModal');
  trackEvent();
}
</script>
