<template>
  <div class="app-container min-h-[100vh] text-[1.75rem]">
    <header class="pt-[4rem] mb-[8rem]">
      <img
        class="w-[18rem] mx-auto"
        src="@/assets/images/lps-logo.svg"
        alt="Lincoln personalization studio logo"
      />
    </header>
    <main class="grid place-items-center gap-[1rem]">
      <template v-if="showErrorMessage">
        <font-awesome-icon
          class="text-[1.5em]"
          :icon="faExclamationTriangle"
        />
        <p>Failed to authenticate user. Please refresh the page and try again.</p>
      </template>
      <template v-else>
        <font-awesome-icon
          spin
          class="text-[1.5em]"
          :icon="faCog"
        />
        <p>Authenticating...</p>
      </template>
    </main>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import store from '@/store';
import apiManager from '@/api';
import { events } from '@/constants';
import { faCog, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

const REDIRECTED_FROM = process.env.VUE_APP_REDIRECTED_FROM;

const router = useRouter();
const route = useRoute();

const showErrorMessage = ref(false);

async function startAuthentication() {
  try {
    const { data: url } = await apiManager.authenticate.loginURL();

    const { redirectedFrom } = route;

    if (redirectedFrom.name !== 'login') {
      localStorage.setItem(REDIRECTED_FROM, redirectedFrom.fullPath);
    }

    window.location.replace(url);
  } catch (error) {
    showErrorMessage.value = true;
  }
}

function redirectUser() {
  const redirectedFrom = localStorage.getItem(REDIRECTED_FROM);

  if (redirectedFrom) {
    localStorage.removeItem(REDIRECTED_FROM);
    router.push(redirectedFrom);
  } else {
    router.push({ name: 'home' });
  }
}

async function authenticateUser() {
  const { code: authCode } = route.query;

  if (!authCode) {
    startAuthentication();
    return;
  }

  try {
    const {
      data: { token, wslxCdsid },
    } = await apiManager.authenticate.token({ authCode });

    store.dispatch('setAuthToken', token);

    const { jti } = JSON.parse(atob(token.split('.')[1]));

    store.dispatch('appInsights/trackEvent', {
      name: `${events.LOGIN} UserID: ${wslxCdsid}, Organization: ${jti || ''}`,
    });

    const [
      { data: { url: iosDownloadLink } },
      { data: pcDownloadLink },
    ] = await Promise.all([
      apiManager.download.downloadIOS(),
      apiManager.download.downloadPC(),
    ]);

    store.commit('setIOSDownloadLink', iosDownloadLink);
    store.commit('setPCDownloadLink', pcDownloadLink);

    redirectUser();
  } catch (error) {
    if (error.response.status === 403) {
      store.commit('setUserIsEnrolled', false);

      redirectUser();

      return;
    }

    showErrorMessage.value = true;
  }
}

authenticateUser();
</script>
