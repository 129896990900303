<template>
  <teleport to="body">
    <transition name="modal--overlay-fade" mode="out-in">
      <div
        v-show="show"
        class="
          grid
          place-items-center
          py-[2rem]
          px-[1rem]
          bg-darkBlue bg-opacity-80
          fixed
          inset-0
          overflow-y-auto
          z-50
        "
        @keypress.esc="closeModal"
      >
        <transition name="modal--fade" mode="out-in">
          <div
            v-show="show"
            role="dialog"
            aria-modal="true"
            class="
              relative
              w-full
              p-[1rem]
              bg-[#EDEDED]
              shadow-[0_3px_6px_#00000029]
              data-[size=md]:max-w-[60.125rem]
              data-[size=lg]:max-w-[70rem]
            "
            :data-size="size"
          >
            <button
              class="
                absolute
                -top-[1.25rem]
                -right-[1.25rem]
                rounded-full
                border-[3px] border-[#EDEDED]
                bg-white
                text-dark2
                w-[2.5rem]
                h-[2.5rem]
                overflow-hidden
                hover:text-secondary
                transition-colors
                duration-300
                ease-in-out
              "
              @click="closeModal"
            >
              <font-awesome-icon class="w-full h-full" :icon="faCircleXmark" />
            </button>
            <div class="p-[3.25rem] bg-white shadow-[0_3px_6px_#00000029]">
              <header class="mb-[2.5rem]">
                <img
                  class="w-[17.75rem] mx-auto"
                  src="@/assets/images/lps-logo.svg"
                  alt="Lincoln personalization studio logo"
                />
              </header>
              <slot />
              <footer v-if="renderCloseButton" class="mt-[2.5rem]">
                <AppButton class="mx-auto" @click="closeModal">
                  Close
                </AppButton>
              </footer>
            </div>
          </div>
        </transition>
      </div>
    </transition>
  </teleport>
</template>

<script setup>
import { watch } from 'vue';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import AppButton from './AppButton.vue';

const emit = defineEmits(['close']);
const props = defineProps({
  show: {
    type: Boolean,
    required: true,
  },
  size: {
    type: String,
    default: 'md',
    validator: (size) => Object.values(['md', 'lg']).includes(size),
  },
  renderCloseButton: {
    type: Boolean,
    default: true,
  },
});

watch(() => props.show, () => {
  if (props.show) {
    document.body.style.overflow = 'hidden';
  } else {
    document.body.style.overflow = 'unset';
  }
});

function closeModal() {
  emit('close');
}
</script>
