<template>
  <div class="
    bg-gray
    max-h-[60vw]
    desktop:max-h-[54vw]
    desktop-xl:max-h-[50vw]
    desktop-xxl:max-h-[45vw]
  ">
    <section
      class="
      app-container
      relative
      -top-[4rem]
      tablet-sm:-top-[6rem]
      desktop-lg:-top-[10rem]"
      :id="sectionId.VIDEO"
      :data-track-event="events.NAVIGATION_SECTION_VIDEO"
    >
      <div class="swiper">
        <div class="swiper-wrapper">
          <div
            v-for="video in videosList"
            class="swiper-slide"
            :key="video.id"
          >
            <div class="app-container max-w-[85rem] relative pb-[52%] h-0">
              <iframe
                ref="videoPlayerList"
                class="absolute top-0 left-0 w-full h-full"
                frameborder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                :title="video.title"
                :src="video.src"
                />
            </div>
          </div>
        </div>
      </div>
      <div class="
        app-container
        flex
        flex-wrap
        justify-center
        gap-[1rem]
        mt-[1rem]
        tablet:gap-[1.5rem]
        tablet:mt-[1.5rem]
      ">
        <button
          v-for="video, index in videosList"
          type="button"
          class="
          w-full
          transition-[color]
          max-w-[8rem]
          text-dark3
          text-center
          text-[1rem]
          tablet:text-[1.125rem]
          tablet:max-w-[13rem]"
          :class="{ 'text-secondary': swiper?.activeIndex === index }"
          :key="video.id"
          @click="swiper.slideTo(index)"
        >
          <img
            class="w-full mb-[0.25rem]"
            alt="Video thumbnail"
            :src="require(`@/assets/images/${video.thumbnail}`)"
          />
          <span>{{ video.title }}</span>
        </button>
      </div>
    </section>
  </div>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import Swiper from 'swiper';
import { events, sectionId } from '@/constants';
import videosList from '@/data/videosList';

import 'swiper/css'; // eslint-disable-line

const store = useStore();
const route = useRoute();

// Swiper settings
const swiper = ref(null);

onMounted(() => {
  swiper.value = new Swiper('.swiper', {
    slidesPerView: 1,
    spaceBetween: 50,
    allowTouchMove: false,
  });

  const { video } = route.query;

  const videoIndex = videosList.findIndex(({ slug }) => slug === video);

  if (videoIndex > -1) {
    swiper.value.slideTo(videoIndex);
  }
});

// Video player
const videoPlayerList = ref([]);

watch(() => swiper.value?.activeIndex, () => {
  videoPlayerList.value.forEach((videoPlayer) => {
    const player = new Vimeo.Player(videoPlayer); // eslint-disable-line

    player.getPaused().then((isPaused) => {
      if (!isPaused) {
        player.pause();
      }
    });
  });
});

function trackVideoEvents() {
  videoPlayerList.value.forEach((videoPlayer, index) => {
    const player = new Vimeo.Player(videoPlayer); // eslint-disable-line

    player.on('play', () => {
      store.dispatch('appInsights/trackEvent', { name: videosList[index].events.played });
    });
  });
}

onMounted(trackVideoEvents);
</script>
