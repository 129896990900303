import { ApplicationInsights } from '@microsoft/applicationinsights-web';

const initializeAppInsights = () => {
  const appInsights = new ApplicationInsights({
    config: {
      connectionString: process.env.VUE_APP_AZURE_INSIGHTS_CONNECTION_STRING,
    },
  });

  appInsights.loadAppInsights();
  appInsights.trackPageView();

  return appInsights;
};

const appInsights = initializeAppInsights();

export default {
  namespaced: true,
  actions: {
    trackEvent(_, { name }) {
      if (!name) return;

      appInsights.trackEvent({ name });
    },
  },
};
