/* eslint-disable */
import { isPast } from 'date-fns';

export const APP_IS_AVAILABLE_FOR_DOWNLOAD = isPast(new Date(process.env.VUE_APP_AVAILABILITY_DATE), 'DD MM YYYY');

export const ENROLLMENT_IS_AVAILABLE = isPast(new Date(process.env.VUE_APP_ENROLLMENT_AVAILABILITY_DATE), 'DD MM YYYY');

export const events = {
  LOGIN: 'User accessed site -',
  ENROLL_NOW_CLICK: 'Click Enroll Now',
  DOWNLOAD_APP_CLICK: 'Click Download App',
  DOWNLOAD_USER_GUIDE_CLICK: 'Click Download User Guide',
  DOWNLOAD_IPAD_APP: 'Download iPad App',
  DOWNLOAD_WINDOWS_APP: 'Download Windows-Based PC App',
  VIDEO_PROGRAM_OVERVIEW_PLAYED: 'Start playing the Program Overview video',
  VIDEO_RETAILER_TESTIMONIAL_PLAYED: 'Start playing the Retailer Testimonial video',
  VIDEO_TUTORIAL_PLAYED: 'Start playing the Tutorial video',
  NAVIGATION_SECTION_VIDEO: 'Navigate to the Video section',
  NAVIGATION_SECTION_PROGRAM_INFO: 'Navigate to the Program Info section',
  NAVIGATION_SECTION_EQUIPMENT_REQUIREMENTS: 'Navigate to the Equipment Requirements section',
  NAVIGATION_SECTION_QUESTIONS: 'Navigate to the Questions section',
  NAVIGATION_SECTION_GETTING_STARTED: 'Navigate to the Getting Started section',
  NAVIGATION_SECTION_SUPPORT: 'Navigate to the Support section',
  FAQ_SECTION_SUPPORT: 'Select Support FAQ section',
  FAQ_SECTION_EQUIPMENT: 'Select Equipment FAQ section',
  FAQ_SECTION_VEHICLES: 'Select Vehicles FAQ section',
  FAQ_SECTION_APP: 'Select Personalization Studio App FAQ section',
  FAQ_SECTION_PRICING: 'Select Pricing FAQ section',
  FAQ_SECTION_GENERAL: 'Select General Questions FAQ section',
};

export const sectionId = {
  HOME: 'home',
  VIDEO: 'videos',
  PROGRAM_INFO: 'program-info',
  EQUIPMENT_REQUIREMENTS: 'equipment-requirements',
  QUESTIONS: 'questions',
  GETTING_STARTED: 'getting-started',
  SUPPORT: 'support',
};
