<template>
  <AppModal
    render-close-button
    :show="$store.getters['showUserGuideModal']"
    @close="$store.dispatch('closeUserGuideModal');"
  >
    <div class="text-center">
      <p class="text-[2.75rem] leading-tight tracking-[0.005em] mb-11">
        Lincoln Personalization Studio<br /> User Guide will be available for download
        in {{ DATE }}.
      </p>
      <p class="text-[2rem] tracking-[0.005em] mb-10">
        Please return to download at that time.
      </p>
    </div>
  </AppModal>
</template>

<script setup>
import { format } from 'date-fns';
import AppModal from './AppModal.vue';

const DATE = format(new Date(process.env.VUE_APP_AVAILABILITY_DATE), 'MMMM');
</script>
