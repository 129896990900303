<template>
  <div class="
    grid
    gap-[1.5rem]
    bg-[#F4F4F4]
    border
    border-[#E5E5E5]
    shadow-[0_3px_6px_#00000029]
  ">
    <slot />
  </div>
</template>
